import isEqual from "lodash.isequal";

export const getFormValues = (
  formfield,
  data,
  dropdownValue,
  multiFieldValue
) => {
  const flatListofForm = flatArray(formfield);
  return flatListofForm.reduce((acc, field) => {
    if (field?.subQuestions) {
      field?.subQuestions?.map((subQueField) => {
        if (
          subQueField?.whenToShow?.includes(dropdownValue?.[field.key]) ||
          subQueField.whenToShow === dropdownValue?.[field.key]
        ) {
          if (subQueField.type === "DROPDOWN") {
            acc[subQueField.key] = dropdownValue?.[subQueField.key] || "";
          } else if (subQueField.type === "MULTIDROPDOWN") {
            acc[subQueField.key] =
              multiFieldValue?.[subQueField.key]?.map((val) => val.value) || [];
          } else {
            acc[subQueField.key] = data?.[subQueField.key] || "";
          }
        } else {
          acc[subQueField.key] = "";
        }
      });
    }

    if (field.key === "Spouse_s_phone_number") {
      acc[field.label] = data?.[field.key] || "";
    } else if (field.type === "DROPDOWN") {
      acc[field.key] = dropdownValue?.[field.key] || "";
    } else if (field.type === "MULTIDROPDOWN") {
      acc[field.key] =
        multiFieldValue?.[field.key]?.map((val) => val.value) || [];
    } else {
      acc[field.key] = data?.[field.key] || "";
    }
    return acc;
  }, {});
};

export const getScopeValues = (
  formfield,
  data,
  dropdownValue,
  multiFieldValue
) => {
  return formfield.reduce((acc, field) => {
    if (field.type === "DROPDOWN") {
      acc[field.key] = dropdownValue?.[field.key];
    } else if (field.type === "MULTIDROPDOWN") {
      acc[field.key] =
        multiFieldValue?.[field.key]?.map((val) => val.value) || [];
    } else {
      acc[field.key] = data?.[field.key] || "";
    }
    return acc;
  }, {});
};

export const flatArray = (fieldsArr) => {
  return fieldsArr.flatMap((slice) => slice);
};

export const checkIfRoomIsChanged = (prevRoom, newRoom) => {
  const roomAreEqual = isEqual(prevRoom, newRoom);
  return !roomAreEqual;
};
