import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ScopeFormFields } from "../FormBuilder/Scope";
import StaircaseButton from "../UI/StaircaseButton";
import DropdownField from "../UI/DropdownField";
import ColorInfo from "../UI/ColorInfo";
import styled from "@emotion/styled";
import ProjectFloors from "../UI/ProjectFloors";

const typoHeaderStyle = {
  textAlign: "center",
  textDecoration: "underline",
  fontSize: "18px",
  fontWeight: "bold",
  color: "#1E2E5A",
};

const ProjectGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
}));

const ScopeForm = ({
  fieldsToRender,
  initialStaircaseData,
  initialScopeData,
  handleChangeStaircaseDropdown,
  data,
  floorClick,
  staircaseClick,
}) => {
  const floors = React.useMemo(() => {
    const grouped = initialScopeData?.reduce((acc, item) => {
      const { Floor, SF } = item;
      const existingGroup = acc.find((group) => group.floor === Floor);

      if (existingGroup) {
        existingGroup.rooms.push(item);
        existingGroup.totalSF += SF; // Add SF to existing group
      } else {
        acc.push({ floor: Floor, rooms: [item], totalSF: SF });
      }

      return acc;
    }, []);
    return grouped;
  }, [initialScopeData, data]);

  const scope = React.useMemo(() => {
    let isInstall = false;
    let isRefinishing = false;
    if (floors?.length > 0) {
      for (const floor of floors) {
        if (floor?.rooms?.length > 0) {
          for (const room of floor.rooms) {
            if (room?.Is_Install) {
              isInstall = true;
            }
            if (room?.Is_Refinishing) {
              isRefinishing = true;
            }
          }
        }
      }
    }
    return { isInstall, isRefinishing, both: isInstall && isRefinishing };
  }, [data, floors, initialScopeData]);

  const refQues = React.useMemo(() => {
    return ScopeFormFields.filter((field) => field?.isRefinishing);
  }, [data, initialScopeData]);

  const insQues = React.useMemo(() => {
    return ScopeFormFields.filter((field) => field?.isInstall);
  }, [data, initialScopeData]);

  const moldingQues = React.useMemo(() => {
    return ScopeFormFields.filter((field) => field?.isMolding);
  }, [data, initialScopeData]);

  return (
    <Box
      sx={{
        pb: "30px",
        width: "100%",
        height: "calc(100vh - 300px)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        {scope.isRefinishing && (
          <Grid item xs={2.5}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={typoHeaderStyle}
                style={{ marginBottom: "20px" }}
              >
                Refinishing
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              // sx={{ maxHeight: "calc(100% - 100px)", overflowY: "auto", px: 1 }}
            >
              {refQues.map((field) => {
                return (
                  <Grid item xs={12} key={field.key}>
                    {fieldsToRender(field)}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {scope.isRefinishing && (
          <Grid
            item
            xs={0.5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Divider orientation="vertical" />
          </Grid>
        )}

        <Grid item xs={scope.isRefinishing ? 2.5 : 5.5}>
          {scope.isInstall && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={typoHeaderStyle}
                  style={{ marginBottom: "20px" }}
                >
                  Installation
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                {insQues.map((field) => {
                  return (
                    <Grid item xs={12} key={field.key}>
                      {fieldsToRender(field)}
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={typoHeaderStyle}
              style={{ margin: scope.isInstall ? "20px 0" : "0 0 20px 0" }}
            >
              Molding
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {moldingQues.map((field) => {
              return (
                <Grid item xs={12} key={field.key}>
                  {fieldsToRender(field)}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "center" }}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={2.5}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <Typography sx={typoHeaderStyle}>Staircase In Scope</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box sx={{ width: "100%" }}>
                {initialStaircaseData?.length > 0 &&
                  initialStaircaseData.map((staircase) => {
                    return (
                      <div style={{ margin: "5px 0" }}>
                        <StaircaseButton
                          staircase={staircase}
                          staircaseClick={staircaseClick}
                        />
                        {!!staircase?.Staircase_Scope && (
                          <Grid container spacing={2} sx={{ mt: "5px" }}>
                            <Grid item xs={12}>
                              <DropdownField
                                id="Staircase Scope"
                                name="Staircase Scope"
                                label="Staircase Scope"
                                value={staircase?.Staircase_Scope}
                                handleChangeDropdown={(e) =>
                                  handleChangeStaircaseDropdown(
                                    e,
                                    staircase?.Staircase_Name,
                                    "Staircase Scope"
                                  )
                                }
                                options={[
                                  "Refinishing Only",
                                  "Unfinished Install with Refinishing",
                                  "Prefinished Install",
                                  "Unfinished NuStair with Refinishing",
                                  "Prefinished NuStair",
                                  "Buff and Recoat",
                                  "Other",
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <DropdownField
                                id="Is there a staircase rip out?"
                                name="Is there a staircase rip out?"
                                label="Is there a staircase rip out?"
                                value={staircase?.Is_there_a_staircase_rip_out}
                                handleChangeDropdown={(e) =>
                                  handleChangeStaircaseDropdown(
                                    e,
                                    staircase?.Staircase_Name,
                                    "Is there a staircase rip out?"
                                  )
                                }
                                options={[
                                  "No Staircase Rip Out Required",
                                  "Rip Out Required: Customer Removing",
                                  "Wall to wall carpet to be removed by FlooredAtHome",
                                  "Wall to wall carpet with stringers to be removed by FlooredAtHome",
                                  "Runner to be removed by FlooredAtHome",
                                ]}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "center" }}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <ColorInfo />
          </Grid>
          <ProjectGrid
            container
            spacing={1}
            sx={{ maxHeight: "550px", overflowY: "auto" }}
          >
            {floors?.length > 0 &&
              floors.map((floor, index) => {
                return (
                  <ProjectFloors
                    addedFloors={floors}
                    floor={floor}
                    index={index}
                    floorClick={floorClick}
                    data={data}
                  />
                );
              })}
          </ProjectGrid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScopeForm;

ScopeForm.propTypes = {
  fieldsToRender: PropTypes.func,
  initialStaircaseData: PropTypes.array,
  initialScopeData: PropTypes.array,
  handleChangeStaircaseDropdown: PropTypes.func,
  data: PropTypes.object,
  floorClick: PropTypes.func,
};
