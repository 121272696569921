import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { CustomerDetailsFormFields } from "../FormBuilder/CustomerDetails";

const CustomeretailsForm = ({ fieldsToRender, dropdownValue }) => {
  return (
    <Box
      sx={{
        pb: "30px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {CustomerDetailsFormFields.map((slice, idx) => {
          return (
            <Grid
              key={idx}
              item
              xs={6}
              sx={{ maxHeight: "550px", overflowY: "auto" }}
            >
              <Grid container spacing={2}>
                {slice.map((field) => {
                  return (
                    <Grid item xs={field.grid || 12} key={field.key}>
                      {fieldsToRender(field)}
                      {field?.subQuestions && (
                        <Grid container spacing={2} sx={{ mt: "1px" }}>
                          {field?.subQuestions?.map((subQueField, i) => {
                            if (
                              dropdownValue?.[field.key] ===
                              subQueField.whenToShow
                            ) {
                              return (
                                <Grid
                                  item
                                  xs={subQueField.grid || 12}
                                  key={`${subQueField.key}+${i}`}
                                >
                                  {fieldsToRender(subQueField)}
                                </Grid>
                              );
                            }
                            return null;
                          })}
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CustomeretailsForm;

CustomeretailsForm.propTypes = {
  fieldsToRender: PropTypes.func,
  dropdownValue: PropTypes.object,
};
