export const CriticalItemFields = [
  [
    // {
    //   key: "Confirmation for OPS to reach out to the customer",
    //   label: "Confirmation for OPS to reach out to the customer",
    //   type: "DROPDOWN",
    //   fieldType: "dropdown",
    //   value: "",
    //   grid: 12,
    //   options: ["Yes", "No"],
    // },
    {
      key: "Requires COI",
      label: "Requires COI",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
    },
    {
      key: "Confirmation that FlooredAtHome will be the only trade",
      label: "Confirmation that FlooredAtHome will be the only trade",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
    },
    {
      key: "Walls & Baseboard to be painted after the project",
      label: "Walls & Baseboard to be painted after the project",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No", "Unknown"],
    },
    {
      key: "Disposal Plan",
      label: "Disposal Plan",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: [
        "Curbside",
        "On Site Dumpster (FAH)",
        "On Site Dupster (Customer)",
        "Off Site Disposal",
        "No Disposal",
        "TBD",
      ],
    },
    {
      key: "Delivery Required",
      label: "Delivery Required",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Delivery Required", "Delivery Not Required"],
      subQuestions: [
        {
          key: "Material Confirmed",
          label: "Material Confirmed",
          type: "DROPDOWN",
          fieldType: "dropdown",
          value: "",
          grid: 12,
          options: [
            "Material Required: Confirmed",
            "Material Required: Not Yet Confirmed",
          ],
          whenToShow: "Delivery Required",
        },
      ],
    },
  ],
];
