import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { TollGateFields } from "../FormBuilder/Tollgates";

const TollgateForm = ({ fieldsToRender }) => {
  return (
    <Box
      sx={{
        pb: "30px",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {TollGateFields.map((slice, idx) => {
          return (
            <Grid
              key={idx}
              item
              xs={6}
              sx={{ maxHeight: "550px", overflowY: "auto" }}
            >
              <Grid container spacing={2}>
                {slice.map((field) => {
                  return (
                    <Grid item xs={field.grid || 12} key={field.key}>
                      {fieldsToRender(field)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TollgateForm;

TollgateForm.propTypes = {
  fieldsToRender: PropTypes.func,
  dropdownValue: PropTypes.object,
};
