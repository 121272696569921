import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { Card, CircularProgress, Divider, StepLabel } from "@mui/material";
import * as yup from "yup";
import styled from "@emotion/styled";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PersonIcon from "@mui/icons-material/Person";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MuiSnackbar from "./UI/MuiSnackbar";
import MuiCustomModal from "./UI/MuiCustomModal";
import Input from "./UI/Input";
import { useReactHookForm } from "./hooks/useReactHookForm";
import CustomeretailsForm from "./Forms/CustomeretailsForm";
import DropdownField from "./UI/DropdownField";
import {
  pushOpsResultDatatoZoho,
  updateRoomName,
} from "./services/finance.service";
import { CustomerDetailsFormFields } from "./FormBuilder/CustomerDetails";
import LogisticForm from "./Forms/LogisticForm";
import { LogisticFormFields } from "./FormBuilder/Logistic";
import ScopeForm from "./Forms/ScopeForm";
import {
  checkIfRoomIsChanged,
  flatArray,
  getFormValues,
  getScopeValues,
} from "../helpers/fieldValueHelper";
import { ScopeFormFields } from "./FormBuilder/Scope";
import MuiAutoComplete from "./UI/MuiAutoComplete";
import TollgateForm from "./Forms/TollgateForm";
import { TollGateFields } from "./FormBuilder/Tollgates";
import { CriticalItemFields } from "./FormBuilder/CriticalItems";
import CriticalItemsForm from "./Forms/CriticalItemsForm";
import GetIconFile from "../assets/GetIconFile";
import _ from "lodash";

const steps = [
  "Project Overview",
  "Project Logistics",
  "Tollgates",
  "Critical Items",
  "Scope",
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #1e2e5a 0%,#263055 50%,#444c74 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#1e2e5a 0%,#263055 50%,#444c74 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #8c8fa9 0%, #444c74 50%, #1e2e5a 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #8c8fa9 0%, #444c74 50%, #1e2e5a 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonIcon />,
    2: <LocalShippingIcon />,
    3: (
      // <img
      //   src="/icons/tollgate.jpeg"
      //   style={{ width: 25, height: 25, borderRadius: 4 }}
      // />
      <GetIconFile iconName="tollgate" data={{ width: "18", height: "18" }} />
    ),
    4: <AnnouncementIcon />,
    5: <PlaylistAddCheckIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const StepperForm = ({ data, id, setData }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [emailModal, setEmailModal] = React.useState(false);
  const [dropdownValue, setDropdownValue] = React.useState({});
  const [multiFieldValue, setMultiFieldValue] = React.useState({});
  const [initialScopeData, setInitialScopeData] = React.useState([]);
  const [initialStaircaseData, setInitialStaircaseData] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [changedRooms, setChangedRooms] = React.useState([]);

  const deepCopyOfResultingScope = _.cloneDeep(data?.Resulting_Scope);
  const [fixedScopeData, setFixedScopeData] = React.useState(
    deepCopyOfResultingScope || []
  );

  const fixedScopeDataRef = React.useRef(fixedScopeData);

  React.useEffect(() => {
    fixedScopeDataRef.current = fixedScopeData;
  }, [fixedScopeData]);

  const EventSchema = yup.object().shape({});

  const { register, errors, getValues, setValue, handleSubmit, watch } =
    useReactHookForm({
      validationSchema: EventSchema,
      defaultValues: {},
      mode: "onChange",
    });

  const setAnotherFormsDefaultValues = React.useCallback(
    (formFields) => {
      const dropdownValues = {};
      const multiDropdown = {};
      const flatTollgateFields = flatArray(formFields);

      flatTollgateFields.forEach((field) => {
        if (!!field?.subQuestions) {
          field.subQuestions.forEach((subField) => {
            if (subField.type === "TEXT") {
              setValue(subField.key, data?.[subField.key]);
            } else if (subField.type === "MULTIDROPDOWN") {
              multiDropdown[subField.key] = data?.[subField.key]
                ? data?.[subField.key]?.map((val) => {
                    return {
                      title: val,
                      value: val,
                    };
                  })
                : [];
            } else if (subField.type === "DROPDOWN") {
              dropdownValues[subField.key] = data?.[subField.key] || "";
            }
          });
        }

        if (field.key === "Spouse_s_phone_number") {
          setValue(field.key, data?.[field.label]);
        } else if (field.type === "TEXT") {
          setValue(field.key, data?.[field.key]);
        } else if (field.type === "MULTIDROPDOWN") {
          multiDropdown[field.key] = data?.[field.key]
            ? data?.[field.key]?.map((val) => {
                return {
                  title: val,
                  value: val,
                };
              })
            : [];
        } else if (field.type === "DROPDOWN") {
          dropdownValues[field.key] = data[field.key];
        }
      }, {});

      setDropdownValue((prev) => ({
        ...prev,
        ...dropdownValues,
      }));
      setMultiFieldValue((prev) => ({
        ...prev,
        ...multiDropdown,
      }));
    },
    [data]
  );

  const setScopeDefaultValues = React.useCallback(() => {
    const dropdownValues = {};
    const multiDropdown = {};
    ScopeFormFields.forEach((field) => {
      if (field.type === "TEXT") {
        setValue(field.key, data?.[field.key]);
      } else if (field.type === "MULTIDROPDOWN") {
        multiDropdown[field.key] = data?.[field.key]
          ? data?.[field.key]?.map((val) => {
              return {
                title: val,
                value: val,
              };
            })
          : [];
      } else {
        dropdownValues[field.key] = data[field.key];
      }
    }, {});

    setDropdownValue((prev) => ({
      ...prev,
      ...dropdownValues,
    }));
    setMultiFieldValue((prev) => ({
      ...prev,
      ...multiDropdown,
    }));
  }, [data]);

  React.useEffect(() => {
    const formFields = [
      ...CustomerDetailsFormFields,
      ...TollGateFields,
      ...LogisticFormFields,
      ...CriticalItemFields,
    ];
    setAnotherFormsDefaultValues(formFields);
    setScopeDefaultValues();
    setInitialScopeData(data?.Resulting_Scope);
    setInitialStaircaseData(data?.Staircases_Scope);
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted(newCompleted);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const openEmailModal = async (e) => {
    e.preventDefault();
    setEmailModal(true);
  };

  const handleChangeDropdown = (event, key, fieldType) => {
    if (fieldType && fieldType === "boolean") {
      setDropdownValue({
        ...dropdownValue,
        [key]: event.target.value === "No" ? false : true,
      });
    } else {
      setDropdownValue({ ...dropdownValue, [key]: event.target.value });
    }
  };

  const handleChangeMultiSelect = (event, newValue, key) => {
    setMultiFieldValue({ ...multiFieldValue, [key]: newValue });
  };

  const getDropDownAnswer = React.useCallback(
    (item, dropdownValue) => {
      return item.fieldType === "boolean"
        ? dropdownValue?.[item.key] === true
          ? "Yes"
          : dropdownValue?.[item.key] === false
          ? "No"
          : ""
        : dropdownValue?.[item.key];
    },
    [data, dropdownValue]
  );

  const fieldsToRender = React.useCallback(
    (item) => {
      switch (item.type) {
        case "TEXT":
          return (
            <Input
              id={item.key}
              name={item.key}
              type={item.fieldType}
              label={item.label}
              register={register}
              errors={errors}
              value={data?.[item.key] || ""}
              getValues={getValues}
              multiline={item?.multiline || false}
            />
          );
        case "DROPDOWN":
          return (
            <DropdownField
              id={item.key}
              name={item.key}
              label={item.label}
              register={register}
              errors={errors}
              value={getDropDownAnswer(item, dropdownValue)}
              handleChangeDropdown={(e) =>
                handleChangeDropdown(e, item.key, item.fieldType)
              }
              options={item.options || []}
            />
          );
        case "MULTIDROPDOWN":
          return (
            <MuiAutoComplete
              id={item.key}
              name={item.key}
              label={item.label}
              options={item.options?.map((op) => ({ title: op, value: op }))}
              handleChangeMultiSelect={(event, newValue) =>
                handleChangeMultiSelect(event, newValue, item.key)
              }
              values={multiFieldValue?.[item.key]}
            />
          );
        default:
          return null;
      }
    },
    [dropdownValue, multiFieldValue, data]
  );

  const handleChangeStaircaseDropdown = (event, name, key) => {
    let tmpStaircase = [...initialStaircaseData];
    tmpStaircase = tmpStaircase?.map((SC) => {
      if (SC?.Staircase_Name === name) {
        if (key === "Staircase Scope") {
          return {
            ...SC,
            Staircase_Scope: event.target.value,
          };
        } else {
          return {
            ...SC,
            Is_there_a_staircase_rip_out: event.target.value,
          };
        }
      }
      return { ...SC };
    });
    setInitialStaircaseData(tmpStaircase);
  };

  const floorClick = (roomObj) => {
    let newScopeData = [...initialScopeData];

    let findIndex = newScopeData.findIndex(
      (item) => item.Room === roomObj.Room
    );
    if (findIndex !== -1) {
      let {
        Is_Install,
        Is_Refinishing,
        Is_Out_Of_Scope_For_Refinish,
        Is_Out_Of_Scope_For_Install,
      } = roomObj;

      if (Is_Install && Is_Refinishing) {
        newScopeData[findIndex].Is_Out_Of_Scope_For_Refinish = true;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Install = true;
        newScopeData[findIndex].Is_Install = false;
        newScopeData[findIndex].Is_Refinishing = false;
      } else if (Is_Install && !Is_Refinishing) {
        newScopeData[findIndex].Is_Refinishing = true;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Refinish = false;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Install = false;
      } else if (!Is_Install && Is_Refinishing) {
        newScopeData[findIndex].Is_Install = true;
        newScopeData[findIndex].Is_Refinishing = false;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Refinish = true;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Install = false;
      } else if (Is_Out_Of_Scope_For_Refinish && Is_Out_Of_Scope_For_Install) {
        newScopeData[findIndex].Is_Refinishing = true;
        newScopeData[findIndex].Is_Install = false;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Refinish = false;
        newScopeData[findIndex].Is_Out_Of_Scope_For_Install = true;
      }
    }

    updateChangedRooms(roomObj, newScopeData, findIndex);
    setInitialScopeData(newScopeData);
  };

  const updateChangedRooms = React.useCallback(
    (roomObj, newScopeData, index) => {
      const fixedRefScopeData = fixedScopeDataRef.current;
      const oldRoomObj = fixedRefScopeData.find(
        (item) => item.Room === roomObj.Room
      );
      const isRoomDataChanged = checkIfRoomIsChanged(
        oldRoomObj,
        newScopeData[index]
      );

      if (isRoomDataChanged) {
        let {
          Is_Install,
          Is_Refinishing,
          Is_Out_Of_Scope_For_Refinish,
          Is_Out_Of_Scope_For_Install,
        } = newScopeData[index];

        let fvRoom = data?.fv_rooms.find((room) => room.Name === roomObj.Room);
        fvRoom = {
          ...fvRoom,
          Is_Install,
          Is_Refinishing,
          Is_Out_Of_Scope_For_Refinish,
          Is_Out_Of_Scope_For_Install,
        };

        setChangedRooms((prev) => {
          const curRoomIndex = prev.findIndex(
            (item) => item.Name === roomObj.Room
          );
          if (curRoomIndex !== -1) {
            prev[curRoomIndex] = fvRoom;
            return prev;
          } else {
            return [...prev, fvRoom];
          }
        });
      } else {
        setChangedRooms((prev) => {
          if (prev.find((item) => item.Name === roomObj.Room)) {
            return prev.filter((item) => item.Name !== roomObj.Room);
          }
          return prev;
        });
      }
    },
    [fixedScopeData, fixedScopeDataRef, changedRooms, data]
  );

  const staircaseClick = (staricase) => {
    let tmpStaircase = [...initialStaircaseData];
    tmpStaircase = tmpStaircase?.map((SC) => {
      if (SC?.Staircase_Name === staricase?.Staircase_Name) {
        return {
          ...SC,
          Staircase_Scope: staricase?.Staircase_Scope
            ? null
            : "Refinishing Only",
          Is_there_a_staircase_rip_out: staricase?.Staircase_Scope
            ? ""
            : staricase?.Is_there_a_staircase_rip_out,
        };
      }
      return { ...SC };
    });
    setInitialStaircaseData(tmpStaircase);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formFields = [
      ...CustomerDetailsFormFields,
      ...LogisticFormFields,
      ...TollGateFields,
      ...CriticalItemFields,
    ];

    const formValues = getFormValues(
      formFields,
      data,
      dropdownValue,
      multiFieldValue
    );

    const scopeValues = getScopeValues(
      ScopeFormFields,
      data,
      dropdownValue,
      multiFieldValue
    );

    let finalBody = {
      id,
      ...formValues,
      ...scopeValues,
      Resulting_Scope: initialScopeData,
      Staircases_Scope: initialStaircaseData,
    };

    const pushData = await pushOpsResultDatatoZoho(finalBody, id);

    if (changedRooms.length > 0) {
      await Promise.all(
        changedRooms.map((room) => {
          const roomData = {
            zc_room: room,
          };
          return updateRoomName(roomData);
        })
      );

      // debugger;
      setData((prevData) => ({
        ...prevData,
        Resulting_Scope: initialScopeData,
      }));

      const deepCopyOfInitialScopeData = _.cloneDeep(initialScopeData);
      // setFixedScopeData(deepCopyOfInitialScopeData);

      setFixedScopeData((currentData) => {
        return deepCopyOfInitialScopeData;
      });

      setChangedRooms([]);
    }

    if (pushData?.data) {
      let message = pushData?.data?.entity?.code;
      setOpen(true);
      setMessage(message);
      setType(message === "success" ? "success" : "error");
    } else {
      setOpen(true);
      setMessage(pushData?.data?.message || "Something went wrong");
      setType("error");
    }
    setIsLoading(false);
  };

  const memoizedCustomerInfo = React.useMemo(() => {
    return (
      <CustomeretailsForm
        fieldsToRender={fieldsToRender}
        dropdownValue={dropdownValue}
      />
    );
  }, [fieldsToRender]);

  const memoizedLogistic = React.useMemo(() => {
    return (
      <LogisticForm
        fieldsToRender={fieldsToRender}
        dropdownValue={dropdownValue}
      />
    );
  }, [dropdownValue]);

  const memoizedTollgates = React.useMemo(() => {
    return (
      <TollgateForm
        fieldsToRender={fieldsToRender}
        dropdownValue={dropdownValue}
      />
    );
  }, [dropdownValue]);

  const memoizedCriticalItems = React.useMemo(() => {
    return (
      <CriticalItemsForm
        fieldsToRender={fieldsToRender}
        dropdownValue={dropdownValue}
      />
    );
  }, [dropdownValue]);

  const memoizedScope = React.useMemo(() => {
    return (
      <ScopeForm
        fieldsToRender={fieldsToRender}
        initialStaircaseData={initialStaircaseData}
        initialScopeData={initialScopeData}
        handleChangeStaircaseDropdown={handleChangeStaircaseDropdown}
        data={data}
        floorClick={floorClick}
        staircaseClick={staircaseClick}
      />
    );
  }, [dropdownValue, multiFieldValue, initialStaircaseData, initialScopeData]);

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
      }}
    >
      <MuiSnackbar
        open={open}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpen(false)}
      />
      <Box sx={{ width: "100%", pt: 3, px: 8 }}>
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={completed[index]}
              // sx={
              //   activeStep === index
              //     ? { backgroundColor: "#80808063", borderRadius: 2 }
              //     : {}
              // }
            >
              <StepButton
                type="button"
                color="inherit"
                onClick={handleStep(index)}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <Divider sx={{ my: "25px" }} />
        </div>
        <div>
          <React.Fragment>
            {/* <form onSubmit={handleSubmit(openEmailModal)}> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formContainer">
                {activeStep === 0 && memoizedCustomerInfo}
                {activeStep === 1 && memoizedLogistic}
                {activeStep === 2 && memoizedTollgates}
                {activeStep === 3 && memoizedCriticalItems}
                {activeStep === 4 && memoizedScope}
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                {/* <Button
                  type="button"
                  variant="contained"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    display: activeStep === 4 ? "none" : "block",
                  }}
                >
                  Next
                </Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    display: activeStep === 4 ? "block" : "none",
                  }}
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Save"
                  ) : (
                    <Box sx={{ mt: 1 }}>
                      <CircularProgress color="primary" size={15} />
                    </Box>
                  )}
                </Button>
              </Box>
            </form>
          </React.Fragment>
        </div>
      </Box>
      <MuiCustomModal
        open={emailModal}
        handleClose={() => {
          setEmailModal(false);
        }}
        onSubmit={(emailVal) => onSubmit(emailVal, formData)}
        loading={isLoading}
      />
    </Card>
  );
};

export default StepperForm;
