import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { LogisticFormFields } from "../FormBuilder/Logistic";

const LogisticForm = ({ fieldsToRender, dropdownValue }) => {
  // const memoizedFields = React.useCallback(() => {
  //   return
  // }, [dropdownValue, LogisticFormFields]);

  return (
    <Box
      sx={{
        pb: "30px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {LogisticFormFields.map((slice, idx) => {
          return (
            <Grid
              key={idx}
              item
              xs={6}
              sx={{ maxHeight: "550px", overflowY: "auto" }}
            >
              <Grid container spacing={2}>
                {slice.map((field) => {
                  return (
                    <Grid item xs={field.grid || 12} key={field.key}>
                      {fieldsToRender(field)}
                      {field?.subQuestions && (
                        <Grid container spacing={2} sx={{ mt: "1px" }}>
                          {field?.subQuestions?.map((subQueField, i) => {
                            if (
                              subQueField?.whenToShow?.includes(
                                dropdownValue?.[field.key]
                              )
                            ) {
                              return (
                                <Grid
                                  item
                                  xs={subQueField.grid || 12}
                                  key={`${subQueField.key}+${i}`}
                                >
                                  {fieldsToRender(subQueField)}
                                </Grid>
                              );
                            }
                            return null;
                          })}
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default LogisticForm;

LogisticForm.propTypes = {
  fieldsToRender: PropTypes.func,
  dropdownValue: PropTypes.object,
};

/* ----------- NEW ------------ */

// import React from "react";
// import PropTypes from "prop-types";
// import { Box, Grid } from "@mui/material";
// import { LogisticFormFields } from "../FormBuilder/Logistic";

// const LogisticForm = ({ fieldsToRender, dropdownValue }) => {
//   const allQuestions = React.useMemo(() => {
//     const extractedQues = [];
//     LogisticFormFields.forEach((field) => {
//       if (
//         field.key === "Property type" &&
//         (dropdownValue?.[field.key] === "Apartment" ||
//           dropdownValue?.[field.key] === "Condo")
//       ) {
//         extractedQues.push(field, ...field.subQustions);
//       } else {
//         extractedQues.push(field);
//       }
//     });
//     return extractedQues;
//   }, [dropdownValue]);

//   const queSlices = React.useMemo(() => {
//     return allQuestions.reduce((acc, _, idx) => {
//       if (idx % 6 === 0) acc.push(allQuestions.slice(idx, idx + 6));
//       return acc;
//     }, []);
//   }, [dropdownValue, allQuestions]);

//   const memoizedFields = React.useMemo(() => {
//     return queSlices?.map((mainQueArr, idx) => {
//       return (
//         <Grid item xs={6} key={idx}>
//           <Grid container spacing={2}>
//             {mainQueArr?.map((que) => {
//               return (
//                 <Grid item xs={12} key={que.key}>
//                   {fieldsToRender(que)}
//                 </Grid>
//               );
//             })}
//           </Grid>
//         </Grid>
//       );
//     });
//   }, [dropdownValue, queSlices]);

//   return (
//     <Box
//       sx={{
//         pb: "30px",
//         width: "100%",
//       }}
//     >
//       <Grid
//         container
//         spacing={2}
//         alignItems="center"
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "flex-start",
//         }}
//       >
//         {memoizedFields}
//       </Grid>
//     </Box>
//   );
// };

// export default LogisticForm;

// LogisticForm.propTypes = {
//   fieldsToRender: PropTypes.func,
//   dropdownValue: PropTypes.object,
// };
