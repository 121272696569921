export const ScopeFormFields = [
  // Refinishing ques
  {
    key: "Is the customer staining the floor",
    label: "Is the customer staining the floor",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isRefinishing: true,
    options: ["Yes", "No"],
  },
  {
    key: "Sealer Samples Or Stain Color Samples",
    label: "Sealer Samples Or Stain Color Samples",
    type: "MULTIDROPDOWN",
    fieldType: "dropdown",
    value: "",
    isRefinishing: true,
    options: [
      "Aged Barrel",
      "Antique Brown",
      "Cherry",
      "Chestnut",
      "Classic Grey",
      "Coffee Brown",
      "Colonial Maple",
      "Country White",
      "Dark Grey",
      "Dark Walnut",
      "Early American",
      "Ebony",
      "English Chestnut",
      "Espresso",
      "Fruitwood",
      "Golden Brown",
      "Golden Oak",
      "Golden Pecan",
      "Gunstock",
      "Hetitage Brown",
      "Jacobean",
      "Medium Brown",
      "Neutral",
      "Nutmeg",
      "Provincial",
      "Red Mahogany",
      "Rosewood",
      "Royal Mahogany",
      "Rustic Beige",
      "Sedona Red",
      "Silvered Grey",
      "Special Walnut",
      "Spice Brown",
      "True Black",
      "Warm Grey",
      "Weathered Oak",
    ],
  },
  {
    key: "Agreed Area For Samples",
    label: "Agreed Area For Samples",
    type: "TEXT",
    fieldType: "text",
    value: "",
    isRefinishing: true,
  },
  {
    key: "Gloss Level Chosen By Customer",
    label: "Gloss Level Chosen By Customer",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isRefinishing: true,
    options: ["Matte", "Satin", "Semi-Gloss", "High Gloss", "TBD"],
  },
  {
    key: "Shine Sample Needed?",
    label: "Shine Sample Needed?",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isRefinishing: true,
    options: ["Yes", "No"],
  },
  {
    key: "3 Coats Of Finish In 1 Day Allowed?",
    label: "3 Coats Of Finish In 1 Day Allowed?",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isRefinishing: true,
    options: ["Yes", "No"],
  },
  {
    key: "Other Stain/Finish Notes",
    label: "Other Stain/Finish Notes",
    type: "TEXT",
    fieldType: "text",
    value: "",
    isRefinishing: true,
  },

  // Installation ques
  {
    key: "Installation Layout Style of New Floor",
    label: "Installation Layout Style of New Floor",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isInstall: true,
    options: ["Straight", "Diagonal", "Herringbone", "Chevron", "Other"],
  },
  {
    key: "Installation Layout Notes",
    label: "Installation Layout Notes",
    type: "TEXT",
    fieldType: "text",
    value: "",
    isInstall: true,
  },
  {
    key: "Will the new floor be lower than the current floor",
    label: "Will the new floor be lower than the current floor",
    type: "DROPDOWN",
    fieldType: "dropdown",
    value: "",
    isInstall: true,
    options: ["Yes", "No", "TBD"],
  },
  {
    key: "Doors Jams Expected To Be Cut?",
    label: "Doors Jams Expected To Be Cut?",
    type: "DROPDOWN",
    fieldType: "boolean",
    value: "",
    isInstall: true,
    options: ["Yes", "No"],
  },
  {
    key: "Doors Expected To Be Cut?",
    label: "Doors Expected To Be Cut?",
    type: "DROPDOWN",
    fieldType: "boolean",
    value: "",
    isInstall: true,
    options: ["Yes", "No"],
  },

  // Molding ques
  {
    key: "Old base molding need to be removed before sanding?",
    label: "Old base molding need to be removed before sanding?",
    type: "DROPDOWN",
    fieldType: "boolean",
    value: "",
    isMolding: true,
    options: ["Yes", "No"],
  },
  {
    key: "Old quarter round need to be removed before sanding?",
    label: "Old quarter round need to be removed before sanding?",
    type: "DROPDOWN",
    fieldType: "boolean",
    value: "",
    isMolding: true,
    options: ["Yes", "No"],
  },
  {
    key: "Other molding notes",
    label: "Other molding notes",
    type: "TEXT",
    fieldType: "text",
    value: "",
    isMolding: true,
  },
];
