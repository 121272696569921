export const LogisticFormFields = [
  [
    {
      key: "Property type",
      label: "Property type",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: [
        "Single Family House",
        "Multi Family House",
        "Apartment",
        "Condo",
        "Townhouse",
        "Gated Community",
        "Commercial Space",
        "Private House",
      ],
      subQuestions: [
        {
          key: "Where To Park",
          label: "Where To Park",
          type: "TEXT",
          fieldType: "text",
          value: "",
          grid: 12,
          whenToShow: "Apartment/Condo",
        },
        {
          key: "Doorman Building?",
          label: "Doorman Building?",
          type: "DROPDOWN",
          fieldType: "dropdown",
          value: "",
          grid: 12,
          options: ["Yes", "No"],
          whenToShow: "Apartment/Condo",
        },
        {
          key: "Floor Level",
          label: "Floor Level",
          type: "TEXT",
          fieldType: "text",
          value: "",
          grid: 12,
          whenToShow: "Apartment/Condo",
        },
        {
          key: "Allowed Hours",
          label: "Allowed Hours",
          type: "TEXT",
          fieldType: "text",
          value: "",
          grid: 12,
          whenToShow: "Apartment/Condo",
        },
        {
          key: "Metal Doors To Be Scribed Around?",
          label: "Metal Doors To Be Scribed Around?",
          type: "DROPDOWN",
          fieldType: "boolean",
          value: "",
          grid: 12,
          whenToShow: "Apartment/Condo",
          options: ["Yes", "No"],
        },
        {
          key: "Other Apartment  Specific Notes",
          label: "Other Apartment  Specific Notes",
          type: "TEXT",
          fieldType: "text",
          value: "",
          grid: 12,
          whenToShow: "Apartment/Condo",
        },
      ],
    },
  ],
  [
    {
      key: "Customer Plan During Project",
      label: "Customer Plan During Project",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: [
        "Customer will stay home but doesn't need to use the floor",
        "Customer move in is after project",
        "Customer will plan to be out of the house for the project",
        "Unknown",
      ],
    },
    {
      key: "How To Get In During 1st Day",
      label: "How To Get In During 1st Day",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "Plan For Locking House Each Day",
      label: "Plan For Locking House Each Day",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "How To Get In Each Day",
      label: "How To Get In Each Day",
      type: "TEXT",
      fieldType: "text",
      value: "",
    },
    {
      key: "Other Logistics Notes",
      label: "Other Logistics Notes",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "Which Sink Customer Is Allowing To Be Used",
      label: "Which Sink Customer Is Allowing To Be Used",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
  ],
];
