// Function to get the SVG icon based on the icon name
const GetIconFile = ({ iconName, data = {}, onClick }) => {
  const { color, width, height, secondColor } = data;
  switch (iconName) {
    case "tollgate":
      return (
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="100"
        //   height="100"
        //   version="1.1"
        //   viewBox="0 0 3006.66 3006.66"
        // >
        //   <g id="Layer_x0020_1">
        //     <metadata id="CorelCorpID_0Corel-Layer" />
        //     <path
        //       class="fil0"
        //       d="M696 1362.65l-637.32 0c-11.04,0 -20,-8.97 -20,-20l0 -978.65c0,-11.04 8.96,-20 20,-20l637.33 0c11.04,0 20,8.96 20,20l0 978.64c-0.02,11.06 -8.96,20.01 -20.02,20.01zm-617.32 -40.01l597.33 0 0 -938.64 -597.33 0 0 938.64z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M888.01 1362.66l-192.02 0c-11.04,0 -20,-8.97 -20,-20l0 -410.67c0,-63.96 52.04,-116 116,-116 63.96,0 116.02,52.02 116.02,116l0 410.65c0,11.06 -8.98,20.02 -20,20.02zm-172.02 -40.02l152 0 0 -390.65c0,-41.9 -34.1,-76 -76,-76 -41.9,0 -76,34.1 -76,76l0 390.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M734.66 384l-714.67 0c-11.04,0 -20,-8.96 -20,-20l0 -98.67c0,-11.04 8.96,-20 20,-20l714.67 0c11.04,0 20,8.96 20,20l0 98.67c0,11.06 -8.96,20 -20,20zm-694.67 -39.98l674.67 0 0 -58.67 -674.67 0 0 58.67z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M696 1004.02l-355.98 0c-34.56,0 -62.67,-28.11 -62.67,-62.68l0 -389.32c0,-34.56 28.11,-62.67 62.67,-62.67l355.98 0c11.04,0 20,8.96 20,20l0 474.67c0,11.05 -8.94,20 -20,20zm-355.98 -474.68c-12.5,0 -22.67,10.17 -22.67,22.67l0 389.33c0,12.5 10.17,22.67 22.67,22.67l335.98 0 0 -434.67 -335.98 0z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1643.99 1078.7l-757.01 0c-11.04,0 -20,-8.97 -20,-20l0 -140.6c0,-11.04 8.97,-20 20,-20l757.01 0c34.54,0 62.67,28.11 62.67,62.68l0 55.26c0,34.54 -28.13,62.65 -62.67,62.65zm-737 -40.02l737 0c12.49,0 22.65,-10.15 22.65,-22.67l0 -55.26c0,-12.5 -10.15,-22.67 -22.65,-22.67l-737 0 0 100.59z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M979.98 1078.7c-3.17,0 -6.36,-0.75 -9.35,-2.34 -9.76,-5.17 -13.48,-17.26 -8.32,-27.03l74.41 -140.6c5.15,-9.78 17.28,-13.5 27.03,-8.32 9.76,5.17 13.49,17.26 8.32,27.03l-74.41 140.6c-3.59,6.79 -10.53,10.65 -17.69,10.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1107.97 1078.7c-3.17,0 -6.36,-0.75 -9.35,-2.34 -9.76,-5.17 -13.48,-17.26 -8.32,-27.03l74.43 -140.6c5.17,-9.78 17.29,-13.5 27.03,-8.32 9.76,5.17 13.48,17.26 8.32,27.03l-74.41 140.6c-3.59,6.79 -10.53,10.65 -17.7,10.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1235.98 1078.7c-3.17,0 -6.36,-0.75 -9.35,-2.34 -9.76,-5.17 -13.48,-17.26 -8.32,-27.03l74.43 -140.6c5.15,-9.78 17.28,-13.5 27.03,-8.32 9.76,5.17 13.49,17.26 8.32,27.03l-74.42 140.6c-3.58,6.79 -10.52,10.65 -17.69,10.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1364 1078.7c-3.17,0 -6.36,-0.75 -9.35,-2.34 -9.76,-5.17 -13.48,-17.26 -8.32,-27.03l74.43 -140.6c5.17,-9.78 17.28,-13.5 27.03,-8.32 9.76,5.17 13.49,17.26 8.32,27.03l-74.43 140.6c-3.57,6.79 -10.51,10.65 -17.68,10.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1492.01 1078.7c-3.17,0 -6.36,-0.75 -9.35,-2.34 -9.76,-5.17 -13.48,-17.26 -8.32,-27.03l74.43 -140.6c5.17,-9.78 17.29,-13.5 27.03,-8.32 9.76,5.17 13.48,17.26 8.32,27.03l-74.43 140.6c-3.58,6.79 -10.53,10.65 -17.68,10.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M434.33 773.01c-5.12,0 -10.24,-1.96 -14.14,-5.86 -7.82,-7.81 -7.82,-20.47 0,-28.28l94.67 -94.67c7.82,-7.82 20.47,-7.82 28.28,0 7.82,7.8 7.82,20.47 0,28.28l-94.67 94.67c-3.91,3.92 -9.02,5.86 -14.14,5.86z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M464.34 855.02c-5.13,0 -10.24,-1.96 -14.14,-5.86 -7.82,-7.81 -7.82,-20.47 0,-28.28l94.67 -94.67c7.82,-7.82 20.47,-7.82 28.28,0 7.82,7.8 7.82,20.47 0,28.28l-94.67 94.67c-3.92,3.91 -9.03,5.86 -14.14,5.86z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M1686.65 1461.33l-1666.65 0c-11.04,0 -20,-8.97 -20,-20l0 -98.67c0,-11.04 8.96,-20 20,-20l1666.65 0c11.04,0 20,8.97 20,20l0 98.67c0.02,11.03 -8.96,20 -20,20zm-1646.65 -40.02l1626.65 0 0 -58.65 -1626.65 0 0 58.65z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M605.33 1124.68l-456 0c-11.04,0 -20,-8.97 -20,-20 0,-11.03 8.96,-20 20,-20l456 0c11.04,0 20,8.97 20,20 0,11.03 -8.96,20 -20,20z"
        //     />
        //     <path
        //       class="fil0"
        //       d="M605.33 1242.01l-456 0c-11.04,0 -20,-8.97 -20,-20 0,-11.03 8.96,-20 20,-20l456 0c11.04,0 20,8.97 20,20 0,11.03 -8.96,20 -20,20z"
        //     />
        //   </g>
        // </svg>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   viewBox="0 0 65 70"
        //   x="0px"
        //   y="0px"
        //   fill="#fff"
        //   width="27px"
        //   height="45px"
        // >
        //   <title>022 - Toll Booth</title>
        //   <rect x="2" y="2" width="28" height="8" rx="1" />
        //   <polygon points="40.59 32 42.59 29 38.41 29 36.41 32 40.59 32" />
        //   <path d="M57,29H52.41l-2,3H57a1,1,0,0,0,1-1V30A1,1,0,0,0,57,29Z" />
        //   <polygon points="43.41 32 47.59 32 49.59 29 45.41 29 43.41 32" />
        //   <polygon points="20.13 16 8 16 8 32 9.46 32 20.13 16" />
        //   <polygon points="24 32 24 16 22.54 16 11.87 32 24 32" />
        //   <path d="M57,54H3a1,1,0,0,0-1,1v3H58V55A1,1,0,0,0,57,54Z" />
        //   <path d="M28,52V12H4V52ZM12,40a1,1,0,0,1-2,0V38a1,1,0,0,1,2,0Zm14,9a1,1,0,0,1-2,0V37a1,1,0,0,1,2,0ZM6,16a2.006,2.006,0,0,1,2-2H24a2.006,2.006,0,0,1,2,2V32a2.006,2.006,0,0,1-2,2H8a2.006,2.006,0,0,1-2-2ZM6,37a1,1,0,0,1,2,0V49a1,1,0,0,1-2,0Z" />
        //   <polygon points="30 32 33.59 32 35.59 29 30 29 30 32" />
        // </svg>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   version="1.1"
        //   x="0px"
        //   y="0px"
        //   viewBox="0 0 85 110"
        //   fill="#fff"
        //   width="27px"
        //   height="45px"
        // >
        //   <path d="M94.325,21.875c-1.465-1.608-6.479-6.667-6.479-6.667s-1.419-1.373-3.263,0.567C82.962,17.48,51.371,48.203,43.793,55.57  l-0.05-27.879c-0.63-8.575-8.827-8.701-8.827-8.701h-6.429h-1.703h-6.431c0,0-8.196,0.126-8.827,8.701l-0.062,34.771  c0,0-0.022,0.629-0.074,0.979c-0.13,0.859-0.447,2.507-1.094,3.61c-0.191,0.327-0.979,1.049-1.157,1.19  c-1.516,1.216-3.785,3.375-3.728,8.06c0.062,5.232,4.854,8.765,8.763,8.765c2.927,0,9.493,0,12.609,0c1.046,0,1.703,0,1.703,0  c3.115,0,9.683,0,12.608,0c3.909,0,8.701-3.532,8.764-8.765c0.037-2.998-0.88-4.96-1.941-6.305l46.314-45.001  C94.231,24.996,95.792,23.483,94.325,21.875z M35.863,45.976H19.344V30.339h16.519V45.976z M48.561,65.092l-4.637-4.675l3.578-3.547  l4.636,4.678L48.561,65.092z M54.865,58.85l-4.636-4.675l3.577-3.547l4.637,4.677L54.865,58.85z M61.341,52.556l-4.637-4.675  l3.577-3.546l4.636,4.675L61.341,52.556z M67.645,46.188l-4.635-4.675l3.575-3.546l4.637,4.677L67.645,46.188z M74.141,40.009  l-4.638-4.676l3.576-3.545l4.637,4.677L74.141,40.009z M80.128,34.145l-4.634-4.675l3.575-3.546l4.637,4.676L80.128,34.145z   M86.624,27.967l-4.637-4.677l3.576-3.545l4.637,4.677L86.624,27.967z" />
        // </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 69 86.25"
          fill="#fff"
          width="27px"
          height="45px"
        >
          <g>
            <path d="M65.47841,61.14355H45.14003V37.88367c0-1.64838-1.33527-3.11005-3.10004-3.11005   c-1.69244,0-3.08997,1.41901-3.08997,3.11005v23.25989h-1.99896V13.46777H10.25477v47.67578H3.47841c-0.55273,0-1,0.44775-1,1   s0.44727,1,1,1h62c0.55273,0,1-0.44775,1-1S66.03114,61.14355,65.47841,61.14355z M34.65712,57.84912c0,0.55225-0.44727,1-1,1   h-20.1084c-0.55273,0-1-0.44775-1-1v-12.604c0-0.55225,0.44727-1,1-1h20.1084c0.55273,0,1,0.44775,1,1V57.84912z    M34.65712,39.21143c0,0.55225-0.44727,1-1,1h-5.63086h-8.84668h-5.63086c-0.55273,0-1-0.44775-1-1V16.7627   c0-0.55225,0.44727-1,1-1h20.1084c0.55273,0,1,0.44775,1,1V39.21143z" />
            <path d="M14.54872,38.21143h3.63086v-1.57666c0-2.99023,2.43359-5.42334,5.42383-5.42334s5.42285,2.43311,5.42285,5.42334v1.57666   h3.63086V17.7627h-18.1084V38.21143z" />
            <rect x="14.54872" y="46.24512" width="18.1084" height="10.604" />
            <path d="M23.60341,33.21143c-1.8877,0-3.42383,1.53564-3.42383,3.42334v1.57666h6.84668v-1.57666   C27.02626,34.74707,25.4911,33.21143,23.60341,33.21143z" />
            <polygon points="37.95106,11.46777 40.32313,11.46777 40.32313,5.85645 6.88368,5.85645 6.88368,11.46777 9.25477,11.46777  " />
            <path d="M45.28999,33.95361l-0.76001-2.83997l2.03003-2.03003l1.84003,6.91003l-1.30005,1.31   c0.03003,0.19,0.04004,0.38,0.04004,0.58002v0.78998l18.18995-18.19c1.53003-1.53003,1.65002-4.10999-0.01001-5.78998   c-1.60987-1.61005-4.19995-1.59003-5.79004,0L41.40004,32.82361c0.20996-0.03998,0.41998-0.04999,0.63995-0.04999   C43.27003,32.77362,44.40998,33.21362,45.28999,33.95361z M59.38002,16.26361l1.83997,6.91003l-2.0199,2.02997l-1.8501-6.91998   L59.38002,16.26361z M55.11,20.53363l1.84009,6.91998l-2.03003,2.02002l-1.84009-6.90997L55.11,20.53363z M50.82997,24.80365   l1.8501,6.91998l-2.03003,2.03003l-1.8501-6.92004L50.82997,24.80365z" />
          </g>
        </svg>
      );

    default:
      return null; // Return null for cases when the icon name is not recognized
  }
};

export default GetIconFile;
