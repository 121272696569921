export const CustomerDetailsFormFields = [
  [
    {
      key: "Customer Name",
      label: "Customer Name",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "Unit",
      label: "Unit",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 6,
    },
    {
      key: "Street",
      label: "Street",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 6,
    },
    {
      key: "City",
      label: "City",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 4,
    },
    {
      key: "State",
      label: "State",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 4,
    },
    {
      key: "Zip Code",
      label: "Zip Code",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 4,
    },
    {
      key: "Mobile_Phone",
      label: "Mobile Phone",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 6,
    },
    {
      key: "Other_Phone",
      label: "Other Phone",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 6,
    },
    {
      key: "Spouse name",
      label: "Spouse name",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 6,
    },
    {
      key: "Spouse_s_phone_number",
      label: "Spouse's phone number",
      type: "TEXT",
      fieldType: "number",
      value: "",
      grid: 6,
    },
    {
      key: "Other Person Delegated By Customer",
      label: "Other Person Delegated By Customer",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "Other Person Delegated By Customer Phone Number",
      label: "Other Person Delegated By Customer Phone Number",
      type: "TEXT",
      fieldType: "number",
      value: "",
      grid: 12,
    },
  ],
  [
    {
      key: "Are we matching any existing floor (Refi. orInst.)",
      label: "Are we matching any existing floor (Refi. orInst.)",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
    },
    {
      key: "Is any leveling needed",
      label: "Is any leveling needed",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
    },
    {
      key: "Scope Confirmed",
      label: "Scope Confirmed",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
    },
    {
      key: "Why Customer is Doing the Project",
      label: "Why Customer is Doing the Project",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: [
        "Moving into a new house",
        "Looking for an exact color",
        "Improve Squeaks",
        "Improve Leveling",
        "Damage to prior floor",
        "Other",
      ],
      subQuestions: [
        {
          key: "Other Reason Customer Is Doing Project",
          label: "Other Reason Customer Is Doing Project",
          type: "TEXT",
          fieldType: "text",
          value: "",
          whenToShow: "Other",
          grid: 12,
        },
      ],
    },
    {
      key: "Most Important thing to customer about project",
      label: "Most Important thing to customer about project",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
    {
      key: "Any project complications to be discussed with OPS",
      label: "Any project complications to be discussed with OPS",
      type: "DROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: ["Yes", "No"],
      subQuestions: [
        {
          key: "Complications to be discussed",
          label: "Complications to be discussed",
          type: "TEXT",
          fieldType: "text",
          value: "",
          whenToShow: "Yes",
          grid: 12,
        },
      ],
    },
  ],
];
