export const TollGateFields = [
  [
    {
      key: "Items For Contractor To Confirm With Customer",
      label: "Items For Contractor To Confirm With Customer",
      type: "MULTIDROPDOWN",
      fieldType: "dropdown",
      value: "",
      grid: 12,
      options: [
        "Subfloor Confirmation Prior to Install",
        "Stain Sample Selected Prior to Staining",
        "Gloss Confirmation Prior to Final Coat",
      ],
    },
    {
      key: "Species And Wood Type Match Test To Be Done At The Start Before Starting Install?",
      label:
        "Species And Wood Type Match Test To Be Done At The Start Before Starting Install?",
      type: "TEXT",
      fieldType: "text",
      multiline: true,
      value: "",
      grid: 12,
    },
    {
      key: "Other Key Notes",
      label: "Other Key Notes",
      type: "TEXT",
      fieldType: "text",
      value: "",
      grid: 12,
    },
  ],
];
